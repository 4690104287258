import "firebase/auth";
import axios from "@/axios";

export default {
  fetchBasicUsers({ commit }, data) {
    return new Promise((resolve, reject) => {
      let fetchBasicUsersUrl =
        "/api/v1/basic-users/basic-user-list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        encodeURIComponent(data.search)  +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&filter=" +
        data.filter;

      if (data.clinicId) {
        fetchBasicUsersUrl += "&clinicId=" + data.clinicId;
      }
      axios
        .get(fetchBasicUsersUrl)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchBasicUserDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/basic-users/basic-user/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchBasicUserOrganizations({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/basic-users/organizations/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateBasicUserDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/basic-users/basic-user/" + data.id, data.data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateBasicUserIsSupplier({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/v1/basic-users/is-supplier/"+ data.id, {
            isSupplier : data.isSupplier
        } )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resetBasicUserPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/api/v1/basic-users/userManagement/basic-user/" +
            data.id +
            "/resetPassword",
            data.data
        )
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  createBasicUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/basic-users/register", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  exportCsv({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/basic-users/export-csv")
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
};
